const LegalMenu = ({ selected }) => {
    const links = ["/legal/terminos-y-condiciones", "/legal/politica-de-privacidad", "/legal/politica-de-cookies"];
    const handleClick = index => {
        window.location.href = links[index];
    };
    return (
        <div className="Header">
            <div className="header-text">
                <img src="/logo/horizontal.svg" alt="Tandem Soluciones Logísticas" />
                <div className="legal-menu-container">
                    <h2>Aspectos Legales</h2>
                    <div className={`legal-menu-item ${selected === 0 && "selected"}`} onClick={() => handleClick(0)}>
                        Términos y Condiciones
                    </div>
                    <div className={`legal-menu-item ${selected === 1 && "selected"}`} onClick={() => handleClick(1)}>
                        Política de Privacidad
                    </div>
                    <div className={`legal-menu-item ${selected === 2 && "selected"}`} onClick={() => handleClick(2)}>
                        Política de Cookies
                    </div>
                </div>
            </div>
            <div className="header-image">
                <img src="/illustrations/Home-Splash.png" alt="Transporte de mercancías" />
            </div>
        </div>
    );
};

export default LegalMenu;
