import LegalMenu from "../../components/LegalMenu";

function Cookies() {
    return (
        <div className="Cookies">
            <LegalMenu selected={2} />
            <div className="Content">
                <h2 className="primary">Política de Cookies</h2>
                <div className="legal-content">
                    <h3 className="legal-title">
                        Qué son las cookies
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>Una cookie es un archivo de texto muy pequeño que un servidor Web puede guardar en el disco duro de un equipo para almacenar algún tipo de información sobre el usuario. La cookie identifica el equipo de forma única, y sólo puede ser leída por el sitio Web que lo envió al equipo.</p>
                        <p>Una cookie no es un archivo ejecutable ni un programa y por lo tanto no puede propagar o contener un virus u otro software malicioso, ni puede tener una longitud superior a 4.000 caracteres.</p>
                    </div>
                    <h3 className="legal-title">
                        Para qué sirven las cookies
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>La utilización de las cookies tiene como finalidad exclusiva recordar las preferencias del usuario (idioma, país, inicio de sesión, características de su navegador, información de uso de nuestra Web, etc.). Recordando sus preferencias no tendremos que, por ejemplo, preguntarle por su país cada vez que visite nuestra Web. Sabremos las características del ordenador que está usando y así podremos ofrecerle una mejor experiencia de navegación. Las cookies pueden ayudar a nuestro sitio Web a distinguir el navegador del usuario como visitante anterior y así guardar y recordar las preferencias que puedan haberse establecido mientras el usuario estaba navegando por el sitio, personalizar las páginas de inicio, identificar qué sectores de un sitio han sido visitados o mantener un registro de selecciones en un “carro de compra”.</p>
                        <p>
                            <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> puede utilizar las cookies con el objeto de reconocer a los usuarios que se hayan registrado y poder ofrecerles un mejor servicio y más personalizado. Asimismo, pueden ser utilizadas para obtener información acerca de la fecha y hora de la última visita del usuario, medir algunos parámetros de tráfico dentro del propio site y estimar el número de visitas realizadas, de manera que <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> pueda enfocar y ajustar los servicios y promociones de forma más efectiva.
                        </p>
                        <p>Normalmente los sitios Web utilizan las cookies para obtener información estadística sobre sus páginas Web. Tenga en cuenta que recogemos datos sobre sus movimientos y uso de nuestra Web como datos estadísticos, no personales. Tipos y finalidades de las cookies.</p>
                        <p>A continuación se presenta una lista de las cookies que puede encontrar en nuestro sitio Web y una breve descripción de su finalidad. Le aseguramos que ninguna de ellas conserva información de identificación personal sobre usted. Algunas cookies personalizan su visita, otras recuerdan sus preferencias, mientras que otras solo nos proveen estadísticas de los visitantes.</p>
                    </div>
                    <h3 className="legal-title">
                        Salvaguardas de protección
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>El usuario puede configurar su navegador para aceptar, o no, las cookies que recibe o para que el navegador le avise cuando un servidor quiera guardar una cookie o borrarlas de su ordenador. Puede encontrar las instrucciones en la configuración de seguridad en su navegador Web.</p>
                        <p>Ejemplos:</p>
                        <ul>
                            <li>Chrome</li>
                            <li>Internet Explorer</li>
                            <li>Firefox</li>
                            <li>Safari</li>
                        </ul>
                        <p>Puede hacer uso de la sección “Ayuda” que encontrará en la barra de herramientas de la mayoría de navegadores para cambiar los ajustes de su ordenador, sin embargo, algunas de las características de nuestros servicios online pueden no funcionar o pueden resultar más complicadas de acceder si rechaza todas las cookies.</p>
                        <p>Muchos navegadores permiten activar un modo privado mediante el cual las cookies se borran siempre después de su visita. Dependiendo de cada navegador, este modo privado puede tener diferentes nombres, abajo puede encontrar una lista de los navegadores más comunes y los diferentes nombres de este “modo privado”:</p>
                        <ul>
                            <li>Internet Explorer 8 y superior: InPrivate</li>
                            <li>FireFox 3.5 y superior: Navegación Privada</li>
                            <li>Google Chrome 10 y superior: Incógnito</li>
                            <li>Safari 2 y superior: Navegación Privada</li>
                            <li>Opera 10.5 y superior: Navegación Privada</li>
                        </ul>
                        <p>Por favor, lea atentamente la sección de ayuda de su navegador para conocer más acerca de como activar el “modo privado”. Podrá seguir visitando nuestra Web aunque su navegador esté en “modo privado”, no obstante, la experiencia de usuario puede no ser óptima y algunas utilidades pueden no funcionar.</p>
                        <p>
                            <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> le agradece que active la aceptación de cookies, esto nos ayuda a obtener datos más precisos que nos permiten mejorar el contenido y el diseño de nuestra página Web para adaptarlo a sus preferencias.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cookies;
