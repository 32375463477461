import CallToAction from "../components/CallToAction";

function Company() {
    return (
        <div className="Company">
            <div className="Header">
                <div className="header-text">
                    <h1>La Empresa</h1>
                    <p>No somos uno más. Todo empieza y acaba en ti. Estamos contigo desde el primer saludo, escuchando tus necesidades, dándoles respuestas de calidad, a tu medida. Y nos mantenemos a tu lado en todo momento, como un auténtico Tándem.</p>
                </div>
                <div className="header-image">
                    <img src="illustrations/Company.png" alt="Transporte de mercancías" />
                </div>
            </div>
            <div className="Content">
                <div className="section-card">
                    <div className="bottom-card">
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/shield.png" alt="Confianza" />
                            </div>
                            <h3 className="item-title primary">Confianza</h3>
                            <div className="item-text">Entendemos la relación con nuestros clientes como un acto de confianza mutua. Sólo así se pueden conseguir resultados extraordinarios.</div>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/heart.png" alt="Pasión" />
                            </div>
                            <h3 className="item-title primary">Pasión</h3>
                            <div className="item-text">Lo nuestro no es sólo un trabajo, es una vocación. Hemos heredado esa pasión de padres a hijos, y ahora seguimos transmitiendo ese carácter familiar y cercano a nuestros clientes.</div>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/medal.png" alt="Calidad" />
                            </div>
                            <h3 className="item-title primary">Calidad</h3>
                            <div className="item-text">¿Cuál es el resultado? Un trabajo de máxima calidad. Estudiamos cada caso de forma personalizada, y ofrecemos soluciones logísticas a medida para cada uno de ellos.</div>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/people.png" alt="Equipo" />
                            </div>
                            <h3 className="item-title primary">Equipo</h3>
                            <div className="item-text">Contamos con un amplio equipo de profesionales, capaces de dar respuesta a estas necesidades de una forma eficaz, rápida y responsable.</div>
                        </div>
                    </div>
                </div>
                <CallToAction />
            </div>
        </div>
    );
}

export default Company;
