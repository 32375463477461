import { useState } from "react";
import CallToAction from "../components/CallToAction";
import Dots from "../components/Dots";
import LegalAcknowledgement from "../components/LegalAcknowledgement";

function Home() {
    const api_url = "https://logisticatandem.com";

    const [emailSent, setEmailSent] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [disabled, setDisabled] = useState(true);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const submitForm = event => {
        event.preventDefault();
        setEmailError(false);
        const formData = {
            name,
            email,
            phone,
            message
        };
        console.log("Form data: ", formData);
        // Send email
        fetch(`${api_url}/api/mail`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then(resJson => {
                console.log(resJson);
                setEmailSent(true);
            })
            .catch(err => {
                console.log(err);
                setEmailError(true);
            });
    };

    return (
        <div className="Home">
            <div className="Header">
                <div className="header-text">
                    <img src="logo/horizontal.svg" alt="Tandem Soluciones Logísticas" />
                    <p>Si necesitas una solución logística y que trabajamos contigo como un gran tándem, mándanos un mensaje explicando lo que necesitas</p>
                </div>
                <div className="header-image">
                    <div className="contact-container">
                        <h2>
                            <Dots color={"secondary"} />
                            CONTÁCTANOS
                        </h2>
                        {!emailSent && !emailError && (
                            <form className="contact-form">
                                <input type="mail" className="col-100" name="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
                                <input type="text" className="col-50" name="name" placeholder="Nombre" onChange={e => setName(e.target.value)} />
                                <input type="phone" className="col-50" name="phone" placeholder="Teléfono" onChange={e => setPhone(e.target.value)} />
                                <textarea className="col-100" name="message" cols="30" rows="10" onChange={e => setMessage(e.target.value)}></textarea>
                                <LegalAcknowledgement enabled={!disabled} onChange={setDisabled} light={true} />
                                <button className={`button primary ${disabled && "disabled"}`} onClick={e => submitForm(e)}>
                                    Enviar
                                </button>
                            </form>
                        )}
                        {emailSent && <div>Email enviado con éxito.</div>}
                        {emailError && <div>Ha ocurrido un error al enviar su petición, por favor inténtelo de nuevo o considere otro medio de contacto, agradecemos su confianza.</div>}
                    </div>
                </div>
            </div>
            <div className="Content">
                <CallToAction />
                <div className="location-container">
                    <div className="location">
                        <h3 className="primary">Contáctanos</h3>
                        <h4>Tándem Soluciones Logísticas, SL.</h4>
                        <p>
                            Calle Tecnología, 17, Planta 0 08840,
                            <br />
                            Viladecans, Barcelona.{" "}
                        </p>
                        <p>
                            E-mail: info@logisticatandem.com
                            <br />
                            Tel: 0034 667 786 687
                        </p>
                    </div>
                    <div className="map-container">
                        <iframe title="tandem-map" width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=calle%20tecnolog%C3%ADa%2017,%20valdecans&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
