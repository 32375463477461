function Dots(props) {
    const { color } = props;
    const colors = {
        primary: "#F29400",
        secondary: "#414141",
        white: "#FFFFFF"
    };
    return (
        <svg style={{ margin: "0 5px" }} width="12" height="25" viewBox="0 0 12 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2.5" cy="22.5" r="2.5" fill={color ? colors[color] : colors["secondary"]} />
            <circle cx="2.5" cy="2.5" r="2.5" fill={color ? colors[color] : colors["secondary"]} />
            <circle cx="9.5" cy="12.5" r="2.5" fill={color ? colors[color] : colors["secondary"]} />
        </svg>
    );
}

export default Dots;
