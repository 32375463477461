import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Nav() {
    const [openMenu, setOpenMenu] = useState(false);
    const location = useLocation();

    useEffect(() => {
        console.log(openMenu);
    }, [openMenu]);

    return (
        <div className="Nav">
            <div className="nav-logo">
                <Link to="/">
                    <img src="/logo/small.svg" alt="Tandem Soluciones Logísticas" />
                </Link>
            </div>
            <div className={`nav-item-container ${openMenu ? "open" : "closed"}`}>
                <div className={`menu-trigger ${openMenu ? "open" : "closed"}`} onClick={e => setOpenMenu(m => !m)}>
                    <img src="icons/close.svg" alt="X" />
                </div>
                <Link to="/">
                    <div className={`nav-item ${location.pathname === "/" && "selected"}`}>Inicio</div>
                </Link>
                <Link to="/servicios">
                    <div className={`nav-item ${location.pathname === "/servicios" && "selected"}`}>Servicios</div>
                </Link>
                <Link to="/empresa">
                    <div className={`nav-item ${location.pathname === "/empresa" && "selected"}`}>La Empresa</div>
                </Link>
                <Link to="/contacto">
                    <div className={`nav-item ${location.pathname === "/contacto" && "selected"}`}>Contacto</div>
                </Link>
                <a href="http://community.logisticatandem.com/app/" target="_blank" rel="noreferrer">
                    <div className={`nav-item platform`}>Acceso</div>
                </a>
            </div>
        </div>
    );
}

export default Nav;
