const LegalAcknowledgement = ({ enabled, onChange, light }) => {
    return (
        <div className={`legal-acknowledgement ${light && "light"}`} onClick={() => onChange(d => !d)}>
            <div className={`legal-acknowledgement-toggle ${light && "light"}`}>
                <div className={`legal-acknowledgement-toggle-element ${enabled && "active"}`} />
            </div>
            <div className={`legal-acknowledgement-label ${light && "light"}`}>
                He leído y acepto los{" "}
                <a href="/legal/terminos-y-condiciones" className="primary">
                    Términos y Condiciones
                </a>{" "}
                y la{" "}
                <a href="/legal/politica-de-privacidad" className="primary">
                    Política de Privacidad
                </a>
                .
            </div>
        </div>
    );
};

export default LegalAcknowledgement;
