import CallToAction from "../components/CallToAction";
import Dots from "../components/Dots";

function Services() {
    return (
        <div className="Services">
            <div className="Header">
                <div className="header-text">
                    <h1>Servicios</h1>
                    <p>En Logística Tándem contamos con un sistema avanzado que nos permite ofrecer soluciones para cualquier tipo de transporte de mercancías. Contamos con una amplia variedad de servicios de distribución y flota para adaptarnos a tus necesidades.</p>
                </div>
                <div className="header-image">
                    <img src="illustrations/Services.png" alt="Servicios de logística" />
                </div>
            </div>
            <div className="Content">
                <CallToAction />
                <div id="distribucion" className="simple-features">
                    <div className="simple-features-left">
                        <div className="tandem-card primary">
                            <h2 className="tandem-card-title">
                                <Dots color={"white"} />
                                Distribución
                            </h2>
                            <div className="tandem-card-content">
                                <p>Carga completa, Grupaje, Serviexpress, Multimodal.</p>
                                <Dots color={"primary"} />
                            </div>
                        </div>
                    </div>
                    <div className="simple-features-right">
                        <div className="simple-features-container">
                            <div className="simple-feature">
                                <h3 className="primary">CARGA COMPLETA</h3>
                                <p>Servicio que mejor se adaptada a las necesidades de grandes volúmenes o envíos completos, “servicio puerta a puerta”.​​</p>
                            </div>
                            <div className="simple-feature">
                                <h3 className="primary">GRUPAJE</h3>
                                <p>Carga fraccionada o lotes que se completa con otras cargas minimizando todo lo posible transbordos de mercancías.</p>
                            </div>
                            <div className="simple-feature">
                                <h3 className="primary">SERVIEXPRESS</h3>
                                <p>Carga completa o fraccionada que se adapta a las necesidades más estrictas en tiempos y fechas de tránsitos.</p>
                            </div>
                            <div className="simple-feature">
                                <h3 className="primary">MULTIMODAL</h3>
                                <p>Servicio para carga completa o fraccionada, máxima flexibilidad para ofrecer un servicio óptimo sin importar el destino final, opciones de transbordo ferroviario o marítimo según las necesidades.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="flota" className="simple-features">
                    <div className="simple-features-left">
                        <div className="tandem-card">
                            <h2 className="tandem-card-title dark">
                                <Dots color={"white"} />
                                Flota
                            </h2>
                            <div className="tandem-card-content">
                                <p>Flota propia y profesionales integrados. Todo tipo de flota, con o sín temperatura controlada</p>
                                <Dots color={"primary"} />
                            </div>
                        </div>
                    </div>
                    <div className="simple-features-right">
                        <div className="simple-features-container">
                            <div className="simple-feature">
                                <h3>FURGONETAS</h3>
                                <ul>
                                    <li>Hasta 3,5 toneladas</li>
                                    <li>Paquetería, ecommerce, express</li>
                                </ul>
                            </div>
                            <div className="simple-feature">
                                <h3>RÍGIDOS</h3>
                                <ul>
                                    <li>Entre 6,00 y 10,00m</li>
                                    <li>&lt; 16/18 toneladas</li>
                                    <li>Paletizado, paquetería, etc.</li>
                                </ul>
                            </div>
                            <div className="simple-feature">
                                <h3>CAMIÓN REMOLQUE</h3>
                                <ul>
                                    <li>Hasta 15,75 m</li>
                                    <li>Hasta 24 toneladas</li>
                                    <li>Paletizado, gran volumen o granel</li>
                                </ul>
                            </div>
                            <div className="simple-feature">
                                <h3>TRAILER</h3>
                                <ul>
                                    <li>13,60 m</li>
                                    <li>Hasta 26 toneladas</li>
                                    <li>Altura estándar 2,70m</li>
                                    <li>Paletizado, gavias, granel</li>
                                </ul>
                            </div>
                            <div className="simple-feature">
                                <h3>MEGA</h3>
                                <ul>
                                    <li>13,60 m</li>
                                    <li>Hasta 24 toneladas</li>
                                    <li>Altura interior 3,00m</li>
                                    <li>Gran volumen</li>
                                </ul>
                            </div>
                            <div className="simple-feature">
                                <h3>GÓNDOLAS</h3>
                                <ul>
                                    <li>13,60 m</li>
                                    <li>Hasta 10 toneladas</li>
                                    <li>Altura interior 3,60m (en 9,60m).</li>
                                    <li>Gran volumen</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features">
                    <h2 className="features-title">
                        <Dots color={"white"} />
                        ¿Por qué con Tandem?
                    </h2>
                    <div className="features-items">
                        <div className="feature">
                            <div className="item">
                                <div className="item-icon">
                                    <img src="icons/headphones.png" alt="Asesor Personal" />
                                </div>
                                <h3 className="item-title primary">Asesor Personal</h3>
                                <div className="item-text">Tendrás tu propio interlocutor, disponible siempre que lo necesites, para todo. Desde la cercanía todo resulta más fácil.</div>
                                <div className="item-text">
                                    <ul>
                                        <li>Asesoramiento</li>
                                        <li>Gestión personalizada</li>
                                        <li>Cotizaciones</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="item">
                                <div className="item-icon">
                                    <img src="icons/pin.png" alt="Trazabilidad" />
                                </div>
                                <h3 className="item-title primary">Trazabilidad</h3>
                                <div className="item-text">Servicio de geolocalización, a tiempo real, con información de la ruta y estado del servicio. Ámbito nacional e internacional.</div>
                                <div className="item-text">
                                    <ul>
                                        <li>Seguimiento de rutas</li>
                                        <li>Incidencias</li>
                                        <li>Documentos</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="item">
                                <div className="item-icon">
                                    <img src="icons/network.png" alt="Cargas On-Line" />
                                </div>
                                <h3 className="item-title primary">Cargas On-Line</h3>
                                <div className="item-text">¿Cargas esporádicas o imprevistas? Tenemos otra manera de cubrir estas necesidades. Como siempre, ahí estaremos también.</div>
                                <div className="item-text">
                                    <ul>
                                        <li>Rutas puntuales</li>
                                        <li>Diversidad de flota: rígidos, tráiler, góndolas, furgonetas, etc.</li>
                                        <li>Servicio de cargas completas, grupajes, exprés.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
