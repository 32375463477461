import "./App.css";
import "./AppMobile.css";
import CookieConsent from "react-cookie-consent";
import Home from "./views/Home";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Nav from "./components/Nav";
import Services from "./views/Services";
import Company from "./views/Company";
import Contact from "./views/Contact";
import TermsAndConditions from "./views/legal/TermsAndConditions";
import Cookies from "./views/legal/Cookies";
import PrivacyPolicy from "./views/legal/PrivacyPolicy";
import Footer from "./components/Footer";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Nav />
                <Switch>
                    <Route path="/servicios" render={() => <Services />} />
                    <Route path="/empresa" render={() => <Company />} />
                    <Route path="/contacto" render={() => <Contact />} />
                    <Route path="/legal/terminos-y-condiciones" render={() => <TermsAndConditions />} />
                    <Route path="/legal/politica-de-privacidad" render={() => <PrivacyPolicy />} />
                    <Route path="/legal/politica-de-cookies" render={() => <Cookies />} />
                    <Route path="/" render={() => <Home />} />
                </Switch>
                {/* <Footer/> */}
                <CookieConsent buttonText="Acepto" cookieName="CookieConsent" style={{ background: "#414141", boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)", borderRadius: "10px", margin: "10px", width: "calc(100% - 20px)", color: "#FFFFFF", textAlign: "left" }} buttonStyle={{ backgroundColor: "#f29400", borderRadius: "10px", color: "white", padding: "10px 20px" }}>
                    Esta página utiliza cookies para mejorar la experiencia de usuario. Para más información sobre el tratamiento de tus datos accede a la{" "}
                    <a className="cookie-link" href="/legal/politica-de-cookies">
                        Política de Cookies
                    </a>
                </CookieConsent>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
