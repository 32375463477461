import React from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
    const location = useLocation();

    return (
        <div className="Footer">
            <div className="footer-company-info">
                <img src="/logo/full-white.svg" alt="Tandem Soluciones Logísticas" />
                <p>Calle Tecnología, 17, Planta 0 08840, Viladecans, Barcelona.</p>
                <p>
                    E-mail: <b>info@logisticatandem.com</b>
                </p>
                <p>
                    Tel: <b>0034 667 786 687</b>
                </p>
            </div>
            <div className={`footer-item-container`}>
                <div className="footer-items-main">
                    <Link to="/">
                        <div className={`footer-item ${location.pathname === "/" && "selected"}`}>Inicio</div>
                    </Link>
                    <Link to="/servicios">
                        <div className={`footer-item ${location.pathname === "/servicios" && "selected"}`}>Servicios</div>
                    </Link>
                    <Link to="/empresa">
                        <div className={`footer-item ${location.pathname === "/empresa" && "selected"}`}>La Empresa</div>
                    </Link>
                    <Link to="/contacto">
                        <div className={`footer-item ${location.pathname === "/contacto" && "selected"}`}>Contacto</div>
                    </Link>
                </div>
                <div className="footer-items-legal">
                    <Link to="/legal/terminos-y-condiciones">
                        <div className={`footer-item ${location.pathname === "/servicios" && "selected"}`}>Términos y Condiciones</div>
                    </Link>
                    <Link to="/legal/politica-de-privacidad">
                        <div className={`footer-item ${location.pathname === "/contacto" && "selected"}`}>Política de Privacidad</div>
                    </Link>
                    <Link to="/legal/politica-de-cookies">
                        <div className={`footer-item ${location.pathname === "/empresa" && "selected"}`}>Política de Cookies</div>
                    </Link>
                    <a href="http://community.logisticatandem.com/app/" target="_blank" rel="noreferrer">
                        <div className={`footer-item platform primary`}>Acceso</div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
