import { HashLink as Link } from "react-router-hash-link";
import CallToAction from "../components/CallToAction";
import Dots from "../components/Dots";

function Home() {
    return (
        <div className="Home">
            <div className="Header">
                <div className="header-text">
                    <img src="logo/horizontal.svg" alt="Tandem Soluciones Logísticas" />
                    <h3>Transporte de mercancías local, nacional e internacional.</h3>
                    <p>No se trata solo de llegar, sino de entenderse y avanzar juntos.</p>
                </div>
                <div className="header-image">
                    <img src="illustrations/Home-Splash.png" alt="Transporte de mercancías" />
                </div>
            </div>
            <div className="Content">
                <div className="section-card">
                    <div className="top-card">
                        <div className="tandem-card primary">
                            <h2 className="tandem-card-title">
                                <Dots color={"white"} />
                                ¿Por qué con tandem?
                            </h2>
                            <div className="tandem-card-content">
                                <h3 className="primary">Te damos 3 motivos.</h3>
                                <p>Somos una empresa que apunta al futuro y nos preocupamos de que nuestros clientes tengan la mejor experiencia posible.</p>
                                <Dots color={"primary"} />
                            </div>
                        </div>
                        <div className="section-illustation">
                            <img src="illustrations/Why-tandem.png" alt="¿Por qué con tándem?" />
                        </div>
                    </div>
                    <div className="bottom-card">
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/headphones.png" alt="Asesor Personal" />
                            </div>
                            <h3 className="item-title primary">Asesor Personal</h3>
                            <div className="item-text">Tu propio consultor siempre disponible. Asesoramiento nuevos proyectos, gestión personalizada y cotizaciones.</div>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/pin.png" alt="Trazabilidad" />
                            </div>
                            <h3 className="item-title primary">Trazabilidad</h3>
                            <div className="item-text">Geolocalización a tiempo real con información de la ruta y estado del servicio. Ámbito nacional e internacional.</div>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/network.png" alt="Cargas online" />
                            </div>
                            <h3 className="item-title primary">Cargas online</h3>
                            <div className="item-text">Necesidades de cargas esporádicas o rutas puntuales. Servicio exprés.</div>
                        </div>
                    </div>
                </div>
                <h2 className="primary">Nuestros Servicios</h2>
                <div className="comparative">
                    <div className="comparative-card primary">
                        <h3>
                            <Dots color={"white"} />
                            DISTRIBUCIÓN
                        </h3>
                        <img src="illustrations/Distribucion.png" alt="Distribución" />
                        <p className="bold">Carga completa, Grupaje, Serviexpress, Multimodal.</p>
                        <Link to={"/servicios#distribucion"} className="button secondary" scroll={el => el.scrollIntoView({ behavior: "smooth", block: "center" })}>
                            Saber más &gt;
                        </Link>
                    </div>
                    <div className="comparative-card secondary">
                        <h3>
                            FLOTA
                            <Dots color={"white"} />
                        </h3>
                        <img src="illustrations/Flota.png" alt="Flota" />
                        <p className="bold">Flota propia y profesionales integrados. Todo tipo de flota, con o sín temperatura controlada: rígidos, tráiler, góndolas, megas, furgonetas, etc.</p>
                        <Link to={"/servicios#flota"} className="button primary" scroll={el => el.scrollIntoView({ behavior: "smooth", block: "center" })}>
                            Saber más &gt;
                        </Link>
                    </div>
                </div>
                <div className="section-card">
                    <div className="top-card">
                        <div className="tandem-card primary">
                            <h2 className="tandem-card-title">
                                <Dots color={"white"} />
                                Juntos llegamos mas lejos
                            </h2>
                            <div className="tandem-card-content two-row">
                                <h3 className="primary">Todo empieza y acaba en ti.</h3>
                                <p>No somos uno más. Estamos contigo desde el primer saludo, escuchando tus necesidades, dándoles respuestas de calidad, a tu medida. Y nos mantenemos a tu lado en todo momento, com un auténtico Tándem.</p>
                                <Dots color={"primary"} />
                            </div>
                        </div>
                        <div className="section-illustation">
                            <img src="illustrations/Together.png" alt="Juntos llegamos más lejos" />
                        </div>
                    </div>
                    <div className="bottom-card">
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/shield.png" alt="Confianza" />
                            </div>
                            <h3 className="item-title primary">Confianza</h3>
                            <div className="item-text">Solo con la confianza de nuestros clientes poder crear un gran tándem.</div>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/heart.png" alt="Pasión" />
                            </div>
                            <h3 className="item-title primary">Pasión</h3>
                            <div className="item-text">Lo nuestro no es solo un trabajo, es una vocación.</div>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/medal.png" alt="Calidad" />
                            </div>
                            <h3 className="item-title primary">Calidad</h3>
                            <div className="item-text">Trabajamos cada día para superar tus expectativas.</div>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img src="icons/people.png" alt="Equipo" />
                            </div>
                            <h3 className="item-title primary">Equipo</h3>
                            <div className="item-text">Amplio equipo de profesionales para darte la mejor respuesta.</div>
                        </div>
                    </div>
                </div>
                <CallToAction />
                <div className="features">
                    <h2 className="features-title">
                        <Dots color={"white"} />
                        Nuestros Clientes
                    </h2>
                    <div className="features-items">
                        <div className="feature">
                            <div className="item">
                                <div className="item-icon">
                                    <img src="icons/bottle.png" alt="Transformación de plástico" />
                                </div>
                                <h3 className="item-title primary">Transformación de plástico</h3>
                                <div className="item-text">Cliente con una de la más avanzadas tecnologías del sector del plástico. Nuestra colaboración se extiende desde hace más de 30 años, con una estrecha relación de confianza y calidad.</div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="item">
                                <div className="item-icon">
                                    <img src="icons/package.png" alt="Embalaje" />
                                </div>
                                <h3 className="item-title primary">Embalaje</h3>
                                <div className="item-text">Cliente de embalaje industrial para garantizar que los traslados a mercados internacionales sea exitoso. Nuestra colaboración nace de la necesidad de nuestros propios clientes, garantía de que están satisfechos.</div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="item">
                                <div className="item-icon">
                                    <img src="icons/car.png" alt="Automoción" />
                                </div>
                                <h3 className="item-title primary">Automoción</h3>
                                <div className="item-text">Cliente que desarrolla y fabrica para la industria automotriz. Nos implicamos al 100% en sus flujos logísticos proporcionando especialmente transporte para la importación de su materia prima.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
