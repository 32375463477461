import LegalMenu from "../../components/LegalMenu";

function PrivacyPolicy() {
    return (
        <div className="PrivacyPolicy">
            <LegalMenu selected={1} />
            <div className="Content">
                <h2 className="primary">Política de Privacidad</h2>
                <div className="legal-content">
                    <div className="legal-preamble">
                        <p>En cumplimiento de lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos, así como cualquier otra normativa aplicable en materia de Protección de Datos, les facilitamos información ampliada en la presente Política de Privacidad.</p>
                    </div>
                    <h3 className="legal-title">
                        Quién es el responsable del tratamiento de sus datos:
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <ul>
                            <li>
                                Identidad: <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b>
                            </li>
                            <li>
                                Dirección postal: <b>Tecnología 17, Planta 0, 08840 Viladecans, Barcelona.</b>
                            </li>
                            <li>
                                Teléfono: <b>667 786 687</b>
                            </li>
                            <li>
                                Email: <b>info@logisticatandem.com</b>
                            </li>
                        </ul>
                    </div>
                    <h3 className="legal-title">
                        Con qué finalidad tratamos sus datos personales:
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>
                            En <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> tratamos la información que nos facilitan las personas interesadas con el fin de prestar y/o comercializar los productos y/o servicios ofertados por nuestra firma.
                        </p>
                    </div>
                    <h3 className="legal-title">
                        Por cuánto tiempo conservaremos sus datos:
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>Los datos personales proporcionados se conservarán por el tiempo imprescindible para la prestación del servicio solicitado o comercialización del producto y durante los plazos legalmente establecidos.</p>
                    </div>
                    <h3 className="legal-title">
                        Cuál es la legitimación para el tratamiento de sus datos:
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>La base legal para el tratamiento de sus datos es la ejecución de un contrato o si no es el caso, será el consentimiento del interesado.</p>
                    </div>
                    <h3 className="legal-title">
                        A qué destinatarios se comunicarán sus datos:
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>Los datos no se comunicarán a terceros salvo obligación legal.</p>
                    </div>
                    <h3 className="legal-title">
                        Cuáles son sus derechos cuando nos facilita sus datos:
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>
                            Cualquier persona tiene derecho a obtener información sobre si <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> estamos tratando datos personales que les conciernan, o no.
                        </p>
                        <p>Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
                        <p>En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</p>
                        <p>
                            En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En este caso, <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
                        </p>
                        <p>Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
                        <p>En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: www.agpd.es</p>
                    </div>
                    <h3 className="legal-title">
                        Más información sobre el tratamiento de sus datos:
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>Cuando precisemos obtener información por su parte, siempre le solicitaremos que nos la proporcione voluntariamente prestando su consentimiento de forma expresa a través de los medios habilitados para ello.</p>
                        <p>
                            El tratamiento de los datos recabados a través de los formularios de recogida de datos del sitio web u otras vías, quedará incorporado al Registro de Actividades de Tratamiento del cual es responsable <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b>
                        </p>
                        <p>
                            <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> trata los datos de forma confidencial y adopta las medidas técnicas y organizativas apropiadas para garantizar el nivel de seguridad adecuado al tratamiento, en cumplimiento de lo requerido por el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 y demás normativa aplicable en materia de Protección de Datos.
                        </p>
                        <p>
                            No obstante, <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> no puede garantizar la absoluta invulnerabilidad de los sistemas, por tanto, no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros puedan causar en los sistemas informáticos, documentos electrónicos o ficheros del usuario.
                        </p>
                        <p>
                            Si opta por abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad, <b>TÁNDEM SOLUCIONES LOGÍSTICAS, S.L.</b> no se hará responsable de las políticas de privacidad de dichos sitios web ni de las cookies que éstos puedan almacenar en el ordenador del usuario.
                        </p>
                        <p>Nuestra política con respecto al envío de nuestros correos electrónicos se centra en remitir únicamente comunicaciones que usted haya solicitado recibir. Si prefiere no recibir estos mensajes por correo electrónico le ofreceremos a través de los mismos la posibilidad de ejercer su derecho de supresión y renuncia a la recepción de estos mensajes, en conformidad con lo dispuesto en el Título III, artículo 22 de la Ley 34/2002, de Servicios para la Sociedad de la Información y de Comercio Electrónico.</p>
                    </div>
                    <h3 className="legal-title">
                        La política aplicada para comprar desde la zona de (OUTLET DE PRESUPUESTOS)
                        <hr className="legal-separator" />
                    </h3>
                    <div className="legal-text">
                        <p>
                            Garantizamos la <b>EXCLUSIVIDAD</b> en la compra del presupuesto pero <b>NO PODEMOS GARANTIZAR</b> que desde la FECHA del presupuesto, el interesado haya contactado con otros profesionales por otros medios ajenos a este o no siga interesado en el servicio.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
