import { useState } from "react";
import Dots from "./Dots";
import LegalAcknowledgement from "./LegalAcknowledgement";

function CallToAction() {
    const api_url = "https://logisticatandem.com";

    const [emailSent, setEmailSent] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [disabled, setDisabled] = useState(true);
    const [phone, setPhone] = useState("");

    const submitForm = event => {
        event.preventDefault();
        setEmailError(false);
        const formData = {
            phone
        };

        // Send email
        fetch(`${api_url}/api/call-us`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then(resJson => {
                console.log(resJson);
                setEmailSent(true);
            })
            .catch(err => {
                console.log(err);
                setEmailError(true);
            });
    };

    return (
        <div className="call-to-action">
            <div className="col-50">
                <h2 className="white">
                    <Dots color={"white"} />
                    Llámanos sin compromiso
                </h2>
                <h3 className="primary">RESOLVEREMOS TUS DUDAS</h3>
            </div>
            <div className="col-50">
                <div className="contact-container">
                    <h2 className="white">
                        <Dots color={"white"} />
                        Tel. 667 786 687
                    </h2>
                    {!emailSent && !emailError && (
                        <form className="contact-form">
                            <input className="col-100" type="phone" name="phone" placeholder="Te llamamos" onChange={e => setPhone(e.target.value)} />
                            <LegalAcknowledgement enabled={!disabled} onChange={setDisabled} />
                            <button className={`button primary ${disabled && "disabled"}`} onClick={e => submitForm(e)}>
                                Enviar
                            </button>
                        </form>
                    )}
                    {emailSent && <div className={"white"}>Email enviado con éxito.</div>}
                    {emailError && <div className={"white"}>Ha ocurrido un error al enviar su petición, por favor inténtelo de nuevo o considere otro medio de contacto, agradecemos su confianza.</div>}
                </div>
            </div>
        </div>
    );
}

export default CallToAction;
